import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Footer from './Footer';
import ProjectBlock from './ProcjectBlock';
import fetchProjectsAndCovers from '../contentfulClient'

const Project = () => {
  let { projectId } = useParams();
  const navigate = useNavigate();
  const openHome = () => navigate('/');

  useEffect(() => {
    getProjects()
  }, [projectId])

  const [projectsAndProject, setProjects] = useState(null)

  const setProjectsAndProject = (apiResponse) => {
    const model = {
      projects: apiResponse.projects,
      project: apiResponse.projects.map(p => p.fields).find(p => p.id === parseInt(projectId))
    }
    setProjects(model)
  }

  const getProjects = () => {
    fetchProjectsAndCovers(setProjectsAndProject)
  }

  const scrollToContacts = () => {
    const element = document.getElementById('contacts-footer');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const bgColor = projectsAndProject ? projectsAndProject.project.backgroundColor : '#A05F1E';

  const bgUrl = () => {
    if (!projectsAndProject) {
      return '/mainbg.jpg';
    }
    const mobileUrl = projectsAndProject.project.mobileCover ? 
      projectsAndProject.project.mobileCover.fields.file.url : projectsAndProject.project.coverImage.fields.file.url;
    return 'https:'+(window.innerWidth < 768 ? mobileUrl : projectsAndProject.project.coverImage.fields.file.url);
  }

  const renderProjectImages = () => {
    if (!projectsAndProject) {
      return <></>
    }

    return projectsAndProject.project.images.map((image, index) => {
      return <img className={`project-content-image-${index % 3 + 1}`} src={`https:${image.fields.file.url}`} loading="lazy" />
    })
  }
  
  const renderRelated = () => {
    if (!projectsAndProject) {
      return <></>
    }

    const sameKindOfProjects = projectsAndProject.projects.filter(p => p.fields.publicProject === projectsAndProject.project.publicProject)
    const selfIndex = sameKindOfProjects.findIndex(p => p.fields.id === projectsAndProject.project.id)
    const allRelatedProjects = sameKindOfProjects.filter(p => p.fields.id !== projectsAndProject.project.id)

    if (allRelatedProjects.length === 0) {
      return <></>
    }

    let relatedProjects = []
    if (allRelatedProjects.length < 3) {
      relatedProjects = allRelatedProjects
    } else if (selfIndex === 0) {
      relatedProjects.push(allRelatedProjects[allRelatedProjects.length - 1])
      relatedProjects.push(allRelatedProjects[0])
    } else if (selfIndex === sameKindOfProjects.length - 1) {
      relatedProjects.push(allRelatedProjects[allRelatedProjects.length - 1])
      relatedProjects.push(allRelatedProjects[0])
    } else {
      relatedProjects.push(allRelatedProjects[selfIndex - 1])
      relatedProjects.push(allRelatedProjects[selfIndex])
    }

    return <>
      <div className='related-projects'>Related projects</div>
      {relatedProjects.map((p, index) => {
          if (window.innerWidth < 768 ) {
              return (<>
                  <ProjectBlock project={p.fields} isImageBlock={true} key={`block-${p.fields.id}-1-${index}`} />
                  <ProjectBlock project={p.fields} isImageBlock={false} key={`block-${p.fields.id}-2-${index}`} />
              </>)    
          }
          return (<>
              <ProjectBlock project={p.fields} isImageBlock={index % 2 === 0 ? true : false} key={`block-${p.fields.id}-3-${index}`} />
              <ProjectBlock project={p.fields} isImageBlock={index % 2 === 0 ? false : true} key={`block-${p.fields.id}-4-${index}`} />
          </>)
      })}
    </>

  }
  
  return (
    <div className="App">
      <div className='project-contact-header'>
        <div className='home-button' onClick={openHome}><img src='/logow.png' className='sticky-header-logo' /></div>
        <div className='contact-button' onClick={scrollToContacts}>GET IN TOUCH</div>
      </div>
      <div className='project-header' style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)), url(${bgUrl()})` }}>
        <div className='white-line' />
        <div className='header-title'>{projectsAndProject ? projectsAndProject.project.projectTitle : ''}</div>
        <div className='white-line' />
        <div className='header-tagline'>
          <div>{projectsAndProject && projectsAndProject.project.clientName ? `client ${projectsAndProject.project.clientName}` : ''}</div>
          <div>{projectsAndProject && projectsAndProject.project.photographer ? `Photos by ${projectsAndProject.project.photographer}` : ''}</div>
        </div>
      </div>
      <div className='main-content'>
        <div className='project-content' style={{ background: `${bgColor}` }}>
          <div className='project-content-text'>
            {projectsAndProject && projectsAndProject.project.description ? projectsAndProject.project.description : ''}
          </div>
          <div className='project-content-images'>
            { renderProjectImages() }
          </div>
        </div>
        { renderRelated() }
      </div>
      <Footer />
    </div>
  );
}

export default Project;
