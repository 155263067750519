import * as React from 'react';
import { useNavigate } from 'react-router-dom';

const ProjectBlock = ({project, isImageBlock}) => {
    const navigate = useNavigate();
    const openProject = () => navigate('/project/'+project.id);

    const ImageBlock = () => {
        const mobileUrl = Object.keys(project).includes('mobileCover') ? project.mobileCover.fields.file.url : project.coverImage.fields.file.url;
        const imageUrl = 'https:'+ (window.innerWidth < 768 ? mobileUrl : project.coverImage.fields.file.url);
        return (<div className='image-content-block-wrapper'><div className='image-content-block' style={{ backgroundImage: `url(${imageUrl})` }}><div className='image-content-block-bg' /></div></div>)
    }

    const TextBlock = () => {
        return (
          <div className='text-content-block'>
            <div className='text-content-block-header'>
              <div className='title-block'>{project.projectTitle}</div>
              <div className='space-tag-block'>{project.publicProject ? 'PUBLIC' : 'PRIVATE'}</div>
            </div>
            <div className='text-content-block-content'>
              {project.shortDescription || project.description}
            </div>
          </div>
        )
    }

    return (
        <div className='main-content-block' onClick={openProject} key={'main-content-block-'+project.id}>
            {isImageBlock ? <ImageBlock /> : <TextBlock />}
        </div>
    )
}

export default ProjectBlock