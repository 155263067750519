import * as React from 'react';

const Footer = () => {
    return (
      <div className='footer' id='contacts-footer'>
        <div className='footer-left'>
          <div className='footer-title'><img src='/logob.png' className='main-footer-logo' /></div>
        </div>
        <div className='footer-center'>
          <div className='footer-link'><a href='tel:+35840236293'>+358 440 236 293</a></div>
          <div className='footer-link'><a href='mailto:info@studiopalinpera.com'>info@studiopalinpera.com</a></div>
          <div className='footer-link'><a href='https://goo.gl/maps/jjMjpJZLHFv68LxA6'>Vuorimiehenkatu 10 (LH9)</a></div>
          <div className='footer-link'><a href='https://goo.gl/maps/jjMjpJZLHFv68LxA6'>00140 Helsinki</a></div>
        </div>
        <div className='footer-right'>
          <div className='footer-link'><a href='https://instagram.com/studioilkkapalinpera?igshid=YmMyMTA2M2Y='>INSTAGRAM</a></div>
          <div className='footer-link'><a href='https://www.linkedin.com/in/studio-ilkka-palinper%C3%A4-658a1078/?originalSubdomain=fi'>LINKEDIN</a></div>
          <div className='footer-link'><a href='https://www.facebook.com/studiopalinpera/'>FACEBOOK</a></div>
        </div>
      </div>
    );
}

export default Footer;