import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import ProjectBlock from './ProcjectBlock';
import fetchProjectsAndCovers from '../contentfulClient'
import { shuffle } from 'lodash';

const Home = () => {
  const navigate = useNavigate();
  const openHome = () => navigate('/');

  useEffect(() => {
    getProjects()
  }, [])

  const [projectsAndCoverImages, setProjects] = useState(null)

  const setProjectsAndCover = (apiResponse) => {
    setProjects(apiResponse)
    const images = window.innerWidth < 768 ? apiResponse.coverImages.mobileImages : apiResponse.coverImages.images
    if (images.length > 0) {
        const newBackground = shuffle(images)[0]
        document.getElementById('main-hero').style.backgroundImage = `linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)), url(https:${newBackground.fields.file.url})`
    }
  }

  const getProjects = () => {
    fetchProjectsAndCovers(setProjectsAndCover)
  }

  const scrollToContacts = () => {
    const element = document.getElementById('contacts-footer');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const renderProjects = () => {
    if (!projectsAndCoverImages) {
        return <></>
    }
    return projectsAndCoverImages.projects.map((p, index) => {
        if (window.innerWidth < 768) {
            return (<>
                <ProjectBlock project={p.fields} isImageBlock={true} key={`block-${p.fields.id}-1-${index}`} />
                <ProjectBlock project={p.fields} isImageBlock={false} key={`block-${p.fields.id}-2-${index}`} />
            </>)    
        }
        return (<>
            <ProjectBlock project={p.fields} isImageBlock={index % 2 === 0 ? true : false} key={`block-${p.fields.id}-3-${index}`} />
            <ProjectBlock project={p.fields} isImageBlock={index % 2 === 0 ? false : true} key={`block-${p.fields.id}-4-${index}`} />
        </>)
    })
  }
  
  return (
    <div className="App">
      <div className='logo-header' id='main-hero'>
        <div className='white-line' />
        <div className='header-title'><img src='/logow.png' className='main-header-logo' /></div>
        <div className='white-line' />
        <div className='header-tagline'>
            { projectsAndCoverImages ? projectsAndCoverImages.coverImages.heroText : ''}
        </div>
      </div>
      <div className='contact-header'>
        <div className='home-button' onClick={openHome}><img src='/logow.png' className='sticky-header-logo' /></div>
        <div className='contact-button' onClick={scrollToContacts}>GET IN TOUCH</div>
      </div>
      <div className='main-content'>
        { renderProjects() }
      </div>
      <Footer />
    </div>
  );
}

export default Home;
