import './App.css';
import './fonts/SangBleuOGSans-Regular-WebS.woff';
import './fonts/Sneak-Regular.otf';
import { Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Project from './components/Project';

function App() {
  return (
    <Routes>
      <Route path='/' element={ <Home /> } />
      <Route path='/project/:projectId' element={ <Project /> } />
    </Routes>
  );
}

export default App;
