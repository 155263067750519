import { sortBy } from 'lodash';

const contentfulClient = require('contentful').createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE,
    environment: 'master', // defaults to 'master' if not set
    accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN
  })

  const fetchProjectsAndCovers = (callback) => {
    contentfulClient.getEntries()
        .then((response) => {
            const coverImages = response.items.filter(i => Object.keys(i.fields).includes('heroId'))
            const projects = response.items.filter(i => Object.keys(i.fields).includes('projectTitle'))
            const result = {
                coverImages: coverImages[0].fields,
                projects: sortBy(projects, ['fields.id']).reverse()
            }
            callback(result)
        })
        .catch(console.error)
  }

  export default fetchProjectsAndCovers;